var anchoventana=$(document).width();
var altoventana=$(document).height();
var _total_redenciones = 0;
var md=768;

(function($) {
  var Sage = {
    'common': {
      init: function() {
        /**
         * declaracion variables globales
         */

         /**
          * init plugins y listeners comunes de proyectos
          */
         initPlugins();
         initListeners();

        $('.js-volverlistadoExperiencias').on('click', function (e){
          $('#section-experiencia-formbono').slideUp("fast");
          $('.pasos-experiencias').slideDown("slow");
        });

        $('.js-sinExperiencias').on('click', function(){
          $('#modal-sinCentros').modal('show');
        });

        /**
         * js del proyecto
         */
         $('.js-uploader').click(function(event) {
           /* Act on the event */
           _nom_input = $(this).data('fichero');
            $('#'+_nom_input).trigger('click');
         });

         $('.fichero').on('change', function(){
            idfichero = $(this).attr('id');

            _fich = $("[data-fichero='"+idfichero+"']" ).attr('id');
            $('#'+_fich).addClass('ticket-ok');
            _nom_tmp = $(this).val().split("\\");
            _nom = _nom_tmp[_nom_tmp.length-1];
           // 
            var fileSize = this.files[0].size;
            var fileName = this.files[0].name;
            _ext = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
            $(this).closest('.form-group').attr('data-tipo-error', '0');//no hay error
            if (fileSize > 10000000){//error tamaño
              $(this).closest('.form-group').attr('data-tipo-error', '2');
              $(this).closest('.form-group').addClass('has-error');
              $(this).closest('.form-group').removeClass('has-sucess');
            }

            _ext = _nom.slice((_nom.lastIndexOf(".") - 1 >>> 0) + 2);
            var validos = ['png', 'jpg', 'pdf', 'jpeg', 'PNG', 'JPG', 'JPEG', 'PDF'];
            if ($.inArray(_ext, validos) < 0){//error extensión
              $(this).closest('.form-group').attr('data-tipo-error', '3');
              $(this).closest('.form-group').addClass('has-error');
              $(this).closest('.form-group').removeClass('has-sucess');
            } 



            _target = $(this).attr('data-targetFichero');
            //alert(_target);
            $('#'+_target).val(_nom);
         });

         $('.js-bt-sesion').on('click', function(){
            $('.js-cont-formulario').fadeOut('slow', function(){
              $('.js-iniciar').hide(); 
              $('#js-formulario-login').fadeIn('slow');
            })
         });

         

         initGA();

         $('.js-configurar').on('click', function(){
             $('.js-formulario-cookies').show();
         });


         $('.placeholder-blanco').on('click', function(){
            if ($(this).val() == '' || $(this).val() == $(this).attr('placeholder')){
                $(this).val('');
                $(this).attr('placeholder', '');
            }
         });

         $('.js-categoria').on('click', function(){
           $('.js-categoria').find('img').removeClass('activa');
           $('.js-mostrar').attr('data-categoria', $(this).attr('id'));
           $(this).find('img').addClass('activa');
           $.ajax({
            url: 'ajax/ajax-actualizar-categoria.php?cat='+$(this).attr('id')
          })
          .done( function (result) {
            document.location.href="experiencias.php";     
          })
         });

         //invalidamos el mostrar, se oculta el botón y se cambia de página al seleccionar categoría
         $('.js-mostrar').on('click', function(){
          if ($(this).attr('data-categoria') == ''){
            PLG_showModalAlerta("¡Ups!", 'Tienes que seleccionar una categoría para continuar.');
          }else{
           

            $.ajax({
              url: 'ajax/ajax-actualizar-categoria.php?cat='+$(this).attr('data-categoria')
            })
            .done( function (result) {
              document.location.href="experiencias.php";     
            })

          }
         });
         $('.js-elegir').on('click', function(){
            $('.js-seleccionar').attr('data-idcategoria', $(this).attr('data-idcategoria'));
            $('.js-seleccionar').attr('data-idexperiencia', $(this).attr('data-idexperiencia'));
            $('#modal-experiencia').find('.descripcion').html($(this).attr('data-descripcion'));
            $('#modal-experiencia').modal('show');
         });
         $('.js-close').on('click', function(){
            $(this).closest('.modal').modal('hide');
         });

         $('.js-seleccionar').on('click', function(){
              var _datos = 'categoria='+$(this).attr('data-idcategoria')+'&experiencia='+$(this).attr('data-idexperiencia');
              $.ajax({
                url: "ajax/ajax-experiencia.php",
                type: "POST",
                dataType: "json",
                data: _datos,
                success: function (response) {
                    $('.js-seleccionar').attr('data-idcategoria', '');
                    $('.js-seleccionar').attr('data-idexperiencia', '');
                    $('#modal-experiencia').modal('hide');
                    if (response['resultado'] == 'OK'){
                      if (response['redirigir'] == 'gracias.php'){
                        document.location.href = response['redirigir'];
                      }else{
                        $('#section-encuesta').modal({
                          show: true,
                          keyboard: false,
                          backdrop: 'static'
                      });
                        
                      }

                    }else{
                       PLG_showModalAlerta("¡Ups!", response['error']['msg_error']);
                    }
                    
                    
                },
                error: function (xhr, status) {
                },
                complete: function (xhr, status) {
                }        
              });
         });

         $('#btn-saltar').on('click', function(){
           document.location.href = "gracias.php";
         });

         
         $('#btn-acepto').on('click', function(){
             $('#cookie_estadisticas').prop('checked', true);
             $('#formCookies').submit();
         });
         $('#btn-rechazo').on('click', function(){
          $('#cookie_estadisticas').prop('checked', false);
          $('#formCookies').submit();
        });

        $('.js-volver').on('click', function(){
          document.location.href = 'categorias.php';
        });

        $('.js-hidden-div').on('click', function(){
          $(this).closest('.alert').addClass('d-none');
        });
      },
      finalize: function() {
      }
    },
    'contacta':{
      init: function() {
        PLG_scrollTo(1,$('#section-contacto'),0,-15);
      },
      finalize: function() {
      }
    },
    'registro':{
      init: function() {
        PLG_scrollTo(1,$('#section-participa'),0,-15);
      },
      finalize: function() {
      }
    },

  };
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.


function resultadoCookies(obj,target, result, spinner_holder){
  console.log(result);
  
 if (result['resultado'] == 'OK' ){
   $("#modal-cookies").fadeOut();
   setCookie("tlcpromo-cookies-tecnica", "true", 365);//la técnica la aprobamos por js como hasta ahora
   
   if (result['estadisticas'] == 'si' && result['codigoGA']!=''){
     //console.log('co::'+result['codigoGA']);
     (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
       (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
       m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
       })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
     //'<?=GA_CODE?>'
       ga('create', result['codigoGA'], 'auto');
       ga('send', 'pageview');

       $('.js-noactivarGA').addClass('js-activarGA');
       $('.js-noactivarGA').removeClass('js-noactivarGA');
       initGA();
    }    
 }
}  
function initGA(){
 $('.js-activarGA').on('click', function(){
   var pag = $(this).attr('data-pagina');
   var evento = $(this).attr('data-evento');
   ga('send', 'event', pag, 'CLICK', evento);
 });  
}

function resultadoRegistro(obj,target, result, spinner_holder){
  //result = res;
  var aleatorio = '';
  for (var i = 0; i < 20; i++) {
    aleatorio += Math.floor(Math.random() * 10);
  }
  if (result['resultado'] == 'OK' ){
      document.location.href="experiencias.php?tc="+aleatorio;
  } else if(result['error']) {
    $('.cont-formulario').find("button[type='submit']").removeClass('btn-loader');
      $('#modal-registro').modal('hide');  
      PLG_showModalAlerta("¡Ups!", result['error']['msg_error']);
  } else {
     $('.cont-formulario').find("button[type='submit']").removeClass('btn-loader');
     $('#modal-registro').modal('hide'); 
    PLG_showModalAlerta("¡Ups!", "Lo sentimos, ha ocurrido un error, inténtelo de nuevo más tarde");
  }
};

function resultadoEncuesta(obj,target, result, spinner_holder){
  if (result['resultado'] == 'OK' ){
    document.location.href="gracias.php";
  }else{
    if (result['resultado'] == 'KOVAL'){
      $('#alerta-encuesta').removeClass('d-none');  
      $('#btn-encuesta').removeClass('btn-loader');      
    }else{
      PLG_showModalAlerta("¡Ups!", "Lo sentimos, ha ocurrido un error, inténtelo de nuevo más tarde");
    }    
  }
}  

function resultadoFiltro(obj,target, res, spinner_holder){
  document.location.href="index.php";
};
function resultadoComprobarCodigoValidado(obj,target, result, spinner_holder){
 // console.log(result);
  $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('btn-loader');

  if (result['resultado'] == 'OK' ){

    $("#form-codigo-validado #codigo_redimir").val(result["datos-user"]['codigo']);
    $("#form-codigo-validado #cliente_id").val(result["datos-user"]['id_cliente']);
    $("#form-codigo-validado #cliente_nombre").val(result["datos-user"]['nombre_cliente']);
    $("#form-codigo-validado #cliente_apellidos").val(result["datos-user"]['apellidos_cliente']);
    $("#form-codigo-validado #cliente_dni").val(result["datos-user"]['dni_cliente']);
    $("#form-codigo-validado #cliente_email").val(result["datos-user"]['email_cliente']);

    if (result['total-redenciones'] == '0' || result['total-redenciones'] == 0){ //si no tiene aún redenciones, tiene que rellenar el formulario inicial
      $("#form-codigo-validado").attr('action', 'formulario-redencion.php');
    }

    $("#form-codigo-validado").submit();





  } else if(result['error']) {
    PLG_showModalAlerta('¡Ups!', result['error']['msg_error']);
  } else {
    PLG_showModalAlerta('¡Ups!', '"Lo sentimos, ha ocurrido un error, inténtelo de nuevo más tarde".');
  }
};

function resultadoRegistroRedencion(obj,target, res, spinner_holder){
 
  $('#btn-enviar').removeClass('btn-loader');
  if (res['resultado'] == 'OK' ){
      $('#form-redencion').submit();
  } else if(res['error']) {

    
    $('#btn-enviar').removeClass('btn-loader');

    PLG_showModalAlerta("¡Ups!", res['error']['msg_error']);
  } else {
    $('#btn-enviar').removeClass('btn-loader');
    PLG_showModalAlerta("¡Ups!", "Lo sentimos, ha ocurrido un error, inténtelo de nuevo más tarde");
  }
};


function envio_contacto(obj,target, result, spinner_holder){

  $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('btn-loader');
  if (result['resultado'] == 'OK'){
      /*$('#myModal').mostrar_msg('Gracias!', 'Hemos recibido correctamente tu mensaje y en breve nos pondremos en contacto contigo para responder a tu consulta.');
      $('.js-contacto-close').trigger("click");*/
       //PLG_showModalAlerta("¡Gracias!", 'Hemos recibido correctamente tu mensaje y en breve nos pondremos en contacto contigo para responder a tu consulta.');
       
       $('.inner-contenedor-formulario').slideUp('fast', function(){
          $('.alerta-gracias').slideDown('slow', function(){
              $('.gracias-participar-experiencias').show();
          });

      });
    }else{
      PLG_showModalAlerta('¡Ups!', 'Ha ocurrido un error y no se ha enviado tu mensaje. Inténtalo en unos minutos o ponte en contacto con nosotros por teléfono.');
    }

};

function initExperiencias(){

        paginaActual=1;
        totalItemsPagina=4;

        $('.js-form-datosPersonales').hide();

        $('.navbar-default .navbar-nav li.menu2').addClass('active');
        $('.navbar-default .navbar-nav li.menu2').addClass('active');


        // Añadimos cargador
       // var spinner_ = new Spinner({color:'#182f20', lines: 12, length: 5, width: 4}).spin();
        /*$('<div id="loading-centros" class="spinner-holder">&nbsp;</div>').appendTo($('.wrapper'));
        //document.getElementById('loading-centros').appendChild(spinner_.el);
        $('<p class="spinner-holder-text"></p>').appendTo($('#loading-centros'));
        $('#loading-centros').show();*/
        
        $('.pasos-experiencias').addClass('.preloader-site');
        $('.preloader-wrapper').show();

        $('.js-reservar').on('click', function (e){
            //$('#myModalConfirmacion').modal('hide');
           // $('#btn-enviar-bono').removeClass('js-mostrar-confirmacion');
            //$('#btn-enviar-bono').attr('type', 'submit');
            //$('#btn-enviar-bono').trigger('click');
           // $('#descargar-bono').submit();
        });

        $('#selProvincia').on('change', function(e){

          
            //filtro arraycompleto
            //$('#selCategorias').removeAttr('disabled');
            arrayFiltro=filtroArray(arrayCentrosCompleto,"Region",$(this).val());

            //arrayFiltro=filtroArray(arrayFiltro,"RewardAlias",$('#selCategorias').val());//RewardAlias
            arrayFiltroActual=arrayFiltro;

            //extraccion array paginacion
            arrayPagina=paginacionArray(arrayFiltro,1,totalItemsPagina);
            //pinta html
            listarItems(".js-listado-contenedor",".js-item-plantilla",arrayPagina);
            callback_descargar_bono();
          $(this).chosen({disable_search_threshold: 10});
        });

        $('#selCategorias').on('change', function(e){
          //filtro arraycompleto
          arrayFiltro=filtroArray(arrayCentrosCompleto,"RewardAlias",$(this).val());
          arrayFiltro=filtroArray(arrayFiltro,"Region",$('#selProvincia').val());
          //console.log(arrayFiltro);
          arrayFiltroActual=arrayFiltro;
          //extraccion array paginacion
          arrayPagina=paginacionArray(arrayFiltro,1,totalItemsPagina);
//console.log(arrayPagina);

          //pinta html
          listarItems(".js-listado-contenedor",".js-item-plantilla",arrayPagina);
          callback_descargar_bono();
          $(this).chosen({disable_search_threshold: 10});
        });

        /*
        eliminado boton buscar
        $('.js-boton-buscar').on('click', function(e){
            //extraccion array paginacion
            arrayPagina=paginacionArray(arrayCentrosCompleto,1,totalItemsPagina);
            //pinta html
            listarItems(".js-listado-contenedor",".js-item-plantilla",arrayPagina);
        });
         */

        //carga de API WS TLC todos los centros de la promo

        $.ajax({
          url: "ajax/ajax-obtener-centros.php",
          type: "POST",
          dataType: "json",
          success: function (response) {

            //$('#loading-centros').hide();
            $('.preloader-wrapper').hide();
            $('#selProvincia').append(response['lista_provincias']);
            $('#selCategorias').append(response['lista_categorias']);

            //en un array todos los centros para su filtrado futuro
            arrayCentrosCompleto=response['listado_items'];
            var compare = function(a,b) {
              if (a.City < b.City)
                return -1;
              else if (a.City > b.City)
                return 1;
              else 
                return 0;
            }


            arrayCentrosCompleto.sort(compare);  

            $("#selProvincia").chosen({disable_search_threshold: 10});
            $("#selCategorias").chosen({disable_search_threshold: 10});
            //console.log(arrayCentrosCompleto);
            
            //extraccion array paginacion
            //arrayPagina=paginacionArray(arrayCentrosCompleto,paginaActual,totalItemsPagina);

            //pinta html
            //listarItems(".js-listado-contenedor",".js-item-plantilla",arrayPagina,"callback_descargar_bono()");
          },
          error: function (xhr, status) {
            $('.preloader-wrapper').hide();
            //$('#loading-centros').hide();
            //alert('xhr = '+xhr+', status = '+status);
            PLG_showModalAlerta("¡Ups!", $('#error_datosKO').val());
            //console.log("ERROR CARGA CENTROS... ******DESCOMENTAR ALERTA MODAL para producción!!! ********");
          },
          complete: function (xhr, status) {
              //$('#showresults').slideDown('slow')
          }
                  // volver listado experiencias

        });



}


/**
FUNCIONES PAGINACION CONTENIDOS POR ARRAY JS 
*/

/**
  * filtra array por valores.
  *
  * @param array :: array completo sobre el que se hace el filtro
  * @param filtro :: clave del filtro
  * @param valor :: valor del filtro
  * @throws
  */
var arrayFiltroActual=null;
function filtroArray(_array,_filtro1,_valor1){
 

  if (_valor1!=""){
    var returnedData = $.grep(_array, function (element, index) {
        return element[_filtro1] == _valor1;
    });

    return returnedData;
  }else{
    return _array;
  }
}


/**
  * pinta elementos de un array de JS paginado.
  *
  * @param array :: array del que saca la info
  * @param pagina :: pagina que tiene que mostrar
  * @param totalItemsPagina :: total items por pagina
  * @throws
  */
function paginacionArray(_array,_pagina,_totalItemsPagina){
  var elementoInicial=_totalItemsPagina*(_pagina-1);
  var elementoFinal=elementoInicial+_totalItemsPagina;
  var arrayTemp=_array.slice(elementoInicial,elementoFinal);
  
  if (_array.length%_totalItemsPagina == 0){
      _tot = _array.length/_totalItemsPagina;
  }
  else {
    _tot = _array.length/_totalItemsPagina+1;
  }

  crearPaginacion("#js-paginacion-contenedor",_pagina,_tot);

  return arrayTemp;
}


/**
  * pinta elementos de un array de JS paginado.
  *
  * @param target :: DOM donde va a insertar HTML
  * @param itemPlantilla :: DOM que sirve de item base para duplicar por cada elemento del array
  * @param array :: array temporal con la paginación resultante
  * @throws
  */
function listarItems(_target,_itemPlantilla,_array,callback){
  var itemPlantilla=$(_itemPlantilla).html();
  $(_target).html("");
  if(_array.length==0){
    $(_target).append("<p class='error-resultado text-center'>Lo sentimos, no hay resultados para esta búsqueda...</p>");
     $(".js-resultado-contenedor").html("");
  }else{
    if(arrayFiltroActual==null){arrayFiltroActual=arrayCentrosCompleto;}
    //$(".js-resultado-contenedor").html("Se han encontrado "+arrayFiltroActual.length+" centros:");
    $(".js-resultado-contenedor").html($('#texto_busqueda').val());
    $.each( _array, function( key1, value1 ) {
      
      var htmlTemp=itemPlantilla;

      $.each(value1, function( key2, value2 ) {

        if(key2=="Phone"){
          var expr = /\(/g;
          value2 = value2.replace(expr, "<small>("); 
        }

        //piden priorizar PartnerSpecifics a Phone
        var find;
        var re;
        
        if(key2=="PartnerSpecifics" && value2!=""){
          //console.log(key2 +">>"+value2);
          find = "{{Phone}}";
          re = new RegExp(find, 'g');
          htmlTemp = htmlTemp.replace(re, "{{PartnerSpecifics}}");
        }

        if(key2=="CategoryAlias" && value2=="CURSO ONLINE"){
          htmlTemp = htmlTemp.replace("inscribe-bono hidden", "inscribe-bono");
        }else{
          if(key2=="CategoryAlias" && value2!="CURSO ONLINE"){
            htmlTemp = htmlTemp.replace("consigue-bono hidden", "consigue-bono");
          }
        }

        //piden eliminar ver más si no hay web
        if(key2=="Website" && value2==""){

          htmlTemp = htmlTemp.replace('<a href="http://{{Website}}" target="_blank">Ver web</a>', "");          

        }


        find = "{{"+key2+"}}";
        re = new RegExp(find, 'g');
        /*if (key2=="VenueName"){
          value2 = value2.toLowerCase();
        }*/

        htmlTemp = htmlTemp.replace(re, value2);

      });

      $(_target).append(htmlTemp);
    });
    eval(callback);

  }
  

}




function crearPaginacion(_target,_pagina,_totalPaginas){

  //console.log(_target+'::'+_pagina+'::'+_totalPaginas);
  if (_totalPaginas<1){
    _totalPaginas = 1;

  }
        var options = {
            currentPage: _pagina,
            totalPages: _totalPaginas,
            size:"normal",
            alignment:"center",
            numberOfPages:"5",
            /*itemTexts: function (type, page, current) {
              switch (type)
              {
              case "first":
                  return "First";
              case "prev":
                  return "";
              case "next":
                  return "";
              case "last":
                  return "Last";
              case "page":
                  return page;
              }
            },
            shouldShowPage:function(type, page, current){
              switch(type)
              {
                  case "first":
                  case "last":
                      return false;
                  default:
                      return true;
              }
            },*/
            tooltipTitles: function (type, page, current) {
                    switch (type) {
                    case "first":
                        return "Tooltip for first page";
                    case "prev":
                        return "Tooltip for previous page";
                    case "next":
                        return "Tooltip for next page";
                    case "last":
                        return "Tooltip for last page";
                    case "page":
                        return "Tooltip for page " + page;
                    }
                },
            onPageChanged: function(e,oldPage,newPage){
                //console.log("Current page changed, old: "+oldPage+" new: "+newPage);
                clickPaginacion(newPage);
            }
        }
        $(_target).bootstrapPaginator(options);
}

function clickPaginacion(_paginaNueva){
  if(arrayFiltroActual==null){
    arrayFiltroActual=arrayCentrosCompleto;
  }
  var arrayPagina=paginacionArray(arrayFiltroActual,_paginaNueva,totalItemsPagina);
  listarItems(".js-listado-contenedor",".js-item-plantilla",arrayPagina,"callback_descargar_bono()");

      var hash = '#section-experiencia-listado';
      var offset= 0;
      $('html, body').animate({
        scrollTop: $(hash).offset().top-offset
      }, 800, function(){
          window.location.hash = hash; 
      });
  
}



function mostrarConfirmacion(){


    $("#section-experiencia-formbono .js-spinner-holder").fadeOut();

    $("#itNombre_descarga").val($('#itNombre_bono').val());
    $("#itDNI_descarga").val($('#itDNI_bono').val());
    $("#itTelefono_descarga").val($('#itTelefono_bono').val());
    $("#itApellidos_descarga").val($('#itApellidos_bono').val());
    $("#itEmail_descarga").val($('#itEmail_bono').val());
    $("#itCentro_descarga").val($('#itCentro').val());
    //$("#itExperiencia_descarga").val($('#itExperiencia').val());
    $("#itFecha_descarga").val($('#itFecha').val());
    $("#itHora_descarga").val($('#itHora').val());

    $("#sp-2").hide();


    $('#myModalConfirmacion').modal(); 
}

function callback_descargar_bono() {
  //inicializo clic descarga bono
  //
  $("#section-experiencia-listado").show();
  $('.js-boton-descarga').on('click', descargar_bono);
//  $('.js-boton-inscripcion').on('click', inscripcion_bono);

  var seccion = $("#section-experiencia-listado");
  var scrollDistance = Math.abs(Math.round(seccion.offset().top) - Math.round($('html, body').scrollTop())+50);
  var velocityFactor = 1;
  var velocity =  velocityFactor*Math.round((1 / 900) * scrollDistance + 5/9);// px/ms
  if($('.navbar-collapse').attr('aria-expanded')=='true'){
    _navbarHeight = $('.navbar-header').outerHeight(false);
    $('.navbar-toggle').click();
  } else {
    _navbarHeight = $('.navbar-collapse').outerHeight(false);

  }
   // $('html, body').stop().animate({scrollTop:seccion.offset().top-_navbarHeight}, scrollDistance/velocity, 'swing', function() { 
       //boton.parent().addClass('activo');

    //});

}

function descargar_bono(e){
  //
  
  var boton=$(this);
  
  $('.pasos-experiencias').slideUp(function(){
      
      $('#section-experiencia-formbono').slideDown(500,function(){
        /*if (_total_redenciones > 0){//no pedir datos del formulario
            $('.js-form-datosPersonales').hide();
        }
        else{
            $('.js-form-datosPersonales').show();
        }*/

        $('.js-form-datosPersonales').show();
       //scrollToDom($('#section-experiencia-formbono'),0);
      });
      
  });


  centro = $(this).closest(".item-centro").find('.titulo_item').html();
  id_centro = $(this).closest(".item-centro").attr('venueid');
  provincia = $(this).closest(".item-centro").attr('provincia');
  id_experiencia = $(this).closest(".item-centro").attr('rewardid');
  experiencia = $.trim($(this).closest(".item-centro").find('.descripcion_item').html());
  direccion_item = $(this).closest(".item-centro").find('.calle').html();
  telefono_centro = $(this).closest(".item-centro").find('.pide_telefono').html();
  poblacion = $(this).closest(".item-centro").find('.ciudad').html();
 
  // Rellenamos el formulario de envío
  $('#descargar-bono1 input[name="centro"]').val(centro);
  $('#descargar-bono1 input[name="experiencia"]').val(experiencia);
  $('#descargar-bono1 input[name="codigo_centro"]').val(id_centro);
  $('#descargar-bono1 input[name="codigo_experiencia"]').val(id_experiencia);
  $('#descargar-bono1 input[name="direccion_centro"]').val(direccion_item);
  $('#descargar-bono1 input[name="telefono_centro"]').val(telefono_centro);
  $('#descargar-bono1 input[name="provincia_centro"]').val(provincia);
  $('#descargar-bono1 input[name="poblacion_centro"]').val(poblacion);


  $("#itExperiencia_descarga").val(experiencia);
  $("#codigo_centro_descarga").val(id_centro);
  $("#codigo_experiencia_descarga").val(experiencia);
  $("#direccion_centro_descarga").val(direccion_item);
  $("#telefono_centro_descarga").val(telefono_centro);
  $("#provincia_centro_descarga").val(provincia);
  $("#poblacion_centro_descarga").val(poblacion);
  

     
  // Enviamos
 // $('#descargar-bono').submit();
}



function respuestaBono(obj,target, result, spinner_holder){
  

  $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('btn-loader');
  $('#myModalConfirmacion').modal('hide');
  
  if (result['resultado'] == 'OK' ){


    //$("#formulario-gracias-descargabono #bonoDescarga").val("https://summerfeelingsgarnier.es/downloads/"+result['file_name'])
    $("#formulario-gracias-descargabono #bonoDescarga").val(result['file_name']);
    $("#formulario-gracias-descargabono").submit();
  //  e.preventDefault();

  } else if(result['error']) {
    PLG_showModalAlerta("¡Ups!", result['error']['msg_error']);
  } else {
    PLG_showModalAlerta("¡Ups!", "Lo sentimos, ha ocurrido un error, inténtelo de nuevo más tarde");
  }
};

